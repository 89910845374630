import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { graphql, Link } from 'gatsby';
import AvekshaPostLink from "../../components/aveksha-post-link";
import Layout from "../../components/layout-aveksha";
import Styles from "../../components/aveksha.module.css";
export const pageQuery = graphql`
  query AvekshaBlogPosts {
    allSitePage (
      filter: { context: { frontmatter: { collection: { eq: "blog" }, category: { eq: "aveksha" } } } }
      sort: { order: DESC, fields: context___frontmatter___date }
    ) {
      edges {
        node {
          id
          context {
            frontmatter {
              date
              path
              title
            }
          }
        }
      }
    }
  }
`;
export const _frontmatter = {
  "title": "AVEKSHA - Reviving Home Based Care",
  "redirect_from": ["/elderly-care", "/home-health-care"]
};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <div className={Styles.avekshaLogo}>
      <Link to="/aveksha" mdxType="Link">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1024px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/c0e3b7169c99b5afa524e65b1c20964e/72e01/Aveksha_Logo.001.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "75%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQBAgX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHdrC46Af/EABoQAAICAwAAAAAAAAAAAAAAAAABAgMRE0H/2gAIAQEAAQUCk8HSxOUa6dbP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGhAAAgIDAAAAAAAAAAAAAAAAARAAEQJRkf/aAAgBAQAGPwJ1uXiB1f/EABoQAQACAwEAAAAAAAAAAAAAAAEAERAhMUH/2gAIAQEAAT8hocuWmjZ7hQQTRtiYQf/aAAwDAQACAAMAAAAQp8//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPxCH/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Qp//EABwQAQABBAMAAAAAAAAAAAAAAAERABAhMWGRof/aAAgBAQABPxBTRJQwa5qL9Eq2FLSKGKh1KQytdW//2Q==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Aveksha Logo 001",
                "title": "Aveksha Logo 001",
                "src": "/static/c0e3b7169c99b5afa524e65b1c20964e/72e01/Aveksha_Logo.001.jpg",
                "srcSet": ["/static/c0e3b7169c99b5afa524e65b1c20964e/f93b5/Aveksha_Logo.001.jpg 300w", "/static/c0e3b7169c99b5afa524e65b1c20964e/b4294/Aveksha_Logo.001.jpg 600w", "/static/c0e3b7169c99b5afa524e65b1c20964e/72e01/Aveksha_Logo.001.jpg 1024w"],
                "sizes": "(max-width: 1024px) 100vw, 1024px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </Link>
    </div>
    <div className={Styles.avekshaHeading}>
      <h2>{`Aveksha`}</h2>
    </div>
    <div className={Styles.pcmhLogo}>
      <Link to="/" mdxType="Link">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1024px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/d57834e74ec1c27063ec76e831b5e7b3/2bef9/biglogo.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "75%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEX0lEQVQ4yz2Te0yTVxjGn7aoC9IbpUV6+UoBEYeii5kzTslUxAH9zlfu3pAog02kSHFAv5YCQ9mQEYkTBIoVOytGtiXMbckubMnQzLn4h5dESFDGRVAQN6NuS/zDswMmnuRJzklOfud9nvc9AFvebTF4JBgxw3RP4KTDNgtoWgjuCpb1f/PhTdNE33dfMFxk6pkihsoxYuRmBAOoVYo7xCwZJSZMML1a99lhhszDZCO2KPyeuSJill/S+9Qadula5uv+ofSo/TOC3jYlGPIY8GOm/knB6KC8Cr+SOIwQTjLKcxi1moGBpHhMEQ4Tgll6xxaN6+nx0U+s2p+uZK2sDq3qKMLhgQT6NjCUHo1ZsgQPiR5/EvNmVjV9QAxeatOAbpdhmMFubIsCnmZpMcJbcJUkoDxvz4InVp3fu/vd3fjoooiGXyI3H6jCj5lvAJSCMjdDglnHYOOzfMQLBqeTxFQ5zew/FPSyP5KXAv9ma8DKlz3mdZjh9XtvZMSWoK7v2FultStpkowfFKKzRwmXP8abwugm4IuMNcEPiP76DDHMwdonibHzHm+KnZyLjJgkuEsiMcDHY9f7pQtp8qKyqMrjR8NEb1V50T6MCOavp9Iiklh29ZPEUN+zfQOy9xVggjdxNCXEejwvee5O4gThCsdZH8YJJ2ONMEmep6nxmA+3DNqi7UHVgYsrDjWt6t69lb1obBknBue4YGqcteoscLRzlv2eWCow7+IPUaj4MoRuC5YzB8VPbBye2TgJC9gkoclBoFtgDuzcVIGa85eL83eop6wRGCPc2XHeVDImcAH6DuLgaC0NszcehvsCtPbGn+X2o6sRoBgWInOvZ5kW3Mwygs0RA25mmScivG1PapHMc+722tIa3U67A89TFPV/pWlTrqbHx9NUed3S4tpieWlzc/h7nmWqg5/2Sg6dMMBPMShEkisZsbLLmXGYz3BuLBgwpC9nXcriav+ExtmxUV/VKrzm9vsM5S0R/TvWI8JxDFzJEZEBnfKyFqgONvWhvC22sKBg+TAxZz7il2DUFinBwZg0fJe9VkJ1DLoe0Ubnye/l7u5bSnf3tKL6DFW5TvWg/luoi2oRYv8kRW0/krY6d9dCzYGGvUHlJxKTS6oKWPc5ug64zTMg0RXi2hbLqzlbU1q3Uc5Aimo/ZdBnarHLqXT5EC52LgraJyK0SESYswOKQ60IdXaWRZU363NLyrD2wwbJPxla4GZqDP5LCsX01nCgh0o4sQ1q0VuocHdTtXiqD99QKDzdkHs+h7ImAJXnHAN5l2qcnZ9pK9s2GitascFRL9tVfQQ0S/nyL98S4nDJloCOnC2I9PikCk8Aaqc3R+U+fZ9pUu3ynWFV1qpFXx3bnw8Vu74Kq2p/UyN2wuA8KcXZF/Pu+lOXvwQmLlvFXvZDW3kC5g9cCK7rlSrdPugczYtV4ql8pet0l9J9+oLK5WvROL0pwWfvQCN6EerqkipqA8BvFFpH4zzrf25frYWN2VLJAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "biglogo",
                "title": "biglogo",
                "src": "/static/d57834e74ec1c27063ec76e831b5e7b3/2bef9/biglogo.png",
                "srcSet": ["/static/d57834e74ec1c27063ec76e831b5e7b3/5a46d/biglogo.png 300w", "/static/d57834e74ec1c27063ec76e831b5e7b3/0a47e/biglogo.png 600w", "/static/d57834e74ec1c27063ec76e831b5e7b3/2bef9/biglogo.png 1024w"],
                "sizes": "(max-width: 1024px) 100vw, 1024px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </Link>
    </div>
    <div className={Styles.subTitle}>
      <p>{`Home Based Primary Care`}</p>
    </div>
    <div className={Styles.tagLine}>
      <p>{`"`}<span>{`Cure Sometimes`}</span>{`, `}<span>{`Treat Often`}</span>{`, `}<span>{`Comfort Always`}</span>{`" --Hippocrates`}</p>
    </div>
    <nav className={Styles.navBar}>
      <ul>
        <li parentName="ul">
          <Link to="/" mdxType="Link">PCMH Home</Link>
        </li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#about"
          }}>{`About`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#services"
          }}>{`Services`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#stories"
          }}>{`Stories`}</a></li>
      </ul>
    </nav>
    <div className={Styles.alertBox}>
      <Link to="/aveksha/covid-19" mdxType="Link"><span className={Styles.blink}>COVID-19 resources</span></Link>
    </div>
    <div className={Styles.book}>
      <a href="tel:+918971544066">📱8971544066</a>
    </div>
    <div id="vision" className={[Styles.visionArea, Styles.writeUp].join(' ')}>
      <h2>Vision</h2>
      <p>We envision healthy communities through teams providing person centred primary care with passion at the comfort of people's home..</p>
      <h2>Mission</h2>
      <p>To deliver world class, compassionate and comprehensive health care services to people and improve their quality of life by adding life to years and not merely years to life. Inspire primary care practitioners to take up this form of holistic Home Based Primary Care as their own mission and grow through them.</p>
    </div>
    <div id="services" className={Styles.servicesWrapper}>
      <h2>Services</h2>
      <div className={Styles.services}>
        <div className={Styles.service}>
          <p><img parentName="p" {...{
              "src": "/126f369365918a96511f5de5edee89eb/palliative-care.svg",
              "alt": null
            }}></img></p>
          <h3>Palliative Care</h3>
          <p>Symptom management, end of life care, cancer, bereavement</p>
        </div>
        <div className={Styles.service}>
          <p><img parentName="p" {...{
              "src": "/c7dea013aff1615711e99a411a74e920/medication-optimization.svg",
              "alt": null
            }}></img></p>
          <h3>Medication Management</h3>
          <p>Poly-pharmacy, drug optimization</p>
        </div>
        <div className={Styles.service}>
          <p><img parentName="p" {...{
              "src": "/5e90e49a3b6653bd00cb99beadc6a334/life-style-management.svg",
              "alt": null
            }}></img></p>
          <h3>Lifestyle Management</h3>
          <p>Chronic disease management, nutrition and exercise, elderly care</p>
        </div>
        <div className={Styles.service}>
          <p><img parentName="p" {...{
              "src": "/61df68f14ac95a8eeae051b08950df9b/post-discharge.svg",
              "alt": null
            }}></img></p>
          <h3>Post-discharge Follow-up</h3>
          <p>Recovery assessment, regular medical checkup</p>
        </div>
        <div className={Styles.service}>
          <p><img parentName="p" {...{
              "src": "/87091c764443f584a8f1dc030ee3f745/mental-well-being.svg",
              "alt": null
            }}></img></p>
          <h3>Mental Well-being</h3>
          <p>Psychometric assessment, counseling, psychotherapy (anxiety, depression, phobias, etc.)</p>
        </div>
        <div className={Styles.service}>
          <p><img parentName="p" {...{
              "src": "/14d24064ebd0172734173ee05007c412/minor-medical-procedure.svg",
              "alt": null
            }}></img></p>
          <h3>Minor Medical Procedures</h3>
          <p>Debridement, bed sores, wound care, vaccination, catheterization, injections</p>
        </div>
      </div>
    </div>
    <div id="about" className={[Styles.writeUp, Styles.writeUpArea].join(' ')}>
      <h2>Our Story</h2>
      <p>Aveksha the Home Based Primary Care Program initiated in April 2019 was conceived to revive and redefine approach to home based care with the principles of family medicine and palliative care; attending to the entire spectrum of cradle to grave.</p>
      <p>Initiated as a physician-pharmacist team, we are dedicated to creating a home health care tribe through training medical and allied healthcare professionals.</p>
      <p>Our inspiration is driven from the Patient Centred Uber model where the canvas is focused mainly on the patient/s with their family in the centre and a team of medical professionals to provide quality health at the comforts of their homes.</p>
      <p>Aveksha was conceived to bring home based health care to the entire spectrum of living: from the newborn to the elderly.</p>
      <p>We initiated services in April 2019 led by a physician-clinical pharmacist team and have seen great response and need for such a service in the community.</p>
      <p>Our inspiration is drawn from patients and their families and we strongly practise shared decision making in health care plans.</p>
      <p>We are invested in grooming the upcoming health professionals in home health care through fellowship and internship opportunities.</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1024px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/6f5ee7f298554745e05b59ad6b968f5f/2bef9/aveksha-model.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "75%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAAD6klEQVQ4y42Se0zTVxTHv79WWh4yU3EIKDChZgMGhNcQV+xowQq0MAXFEVwQCI+2UgHlkRLQwgA1kHU81MAKMsmMyIBsTAazYWABBQeIMLHKwGFwmAWWWGAZyd2vGP5ZloyTe27OvTnnc+/33gP8y8pWI0EuqkFK60DyWljLFaVnSaVKqlx6EpPy50yCaOU+K3x1EjHLU3jQu4L/NSPwRXshNd9diGldsb2hqqB5oKZeF/dI35a5MF19efYPh+rZV6iYeUltClj6VwQ+J1EoMUi3Fb3mSc+OnrvqdN2wsKuFvLyhNbSM/bh0rnuQWI4NrmH4p+XNAS/QwPNEbqaa/yiueu6U2v/2zKRX+8pI58Ccpr/zd/n9EcKeHiabBK5GYJYkUnMkDYSUcJZfxaqXFuVVOsOdgr7XPXkvyPO3JpbnMbL4bF3yl34cOo+s12a9u/W/gWRRDtKbBm1XMOPvNVmg/vExfutUq+8wGfX5ZqCO0Uu8MZHqQY0fsqX0YTbUBrDRnwOkDx2A9C4Puc+CqRy9kJL28aDuEEAmdYcsyWPjdAbtTGP8iVyCingRRYTAzyJbLB00Q/p5DT6r1YKw6KzTD/iQ9Qci56kQNBAyHQ+SMlfYueyAjSuXcraGRVbd5S0HZCqWzXa2hULMp1rC3TG+n+HcI9gZNcS39NbVXcfKvV/Qb08DFUagjsfOfSq0o4G2st4PWaefCNclMAFz7EtkweO4FfyTbLBXYLKb3iMJQLmYF9nD52R/JXKNg+gSG6ILkIRIYJSKMxNBTlmPglRZ40GF2ZMC+9hbfrBytDSnmVtqqkZN3KztTnrYOkRvvLNJpJKL5G8DolIygeS7XEZyoxcjtYmOmygkTQegrEPCKnoYuuNEa4A1yBpdsodpybHaKrMyR+57XLMM3/1eCp99HvkujmzHN0xTYWPDsU/v3c4Ja711gl6zwWoCM14NGNxKMaQpgjZPjC7AsXsnO72DA6NmhhlAD6bZG/VGZ5oab409Kbs+7mxJOzmu1cQMdCsQoX1n/evc6Pm71CSGXpXHHVPleHbFhjlcs7W0STucuFdQP7k9tHqA5SSrRENmBjXlQWD8cFdlAtc8pTzcXlkr8G1oPyz4otwzVBF3NDor2sevsBwYzFaYThcpVc9LCur1+Rl8uoqSNM/lR96cK4v4eub9uH6CqBsa5vEGTxy54mUX3/ZBVki1OBZH2zwhbnb2TFaIH1ahtE+NpPUO++FUCuPX4nyXhUtF3o+VmdZ0vyOisvftg1eGrPll37MP1Y0h8poG0Rp/HKn1ZWZPBFrI77izUDIIhBTjZjCYNWdMd1/NZFiR37bhHzmPmRMxdT3GAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "aveksha model",
              "title": "aveksha model",
              "src": "/static/6f5ee7f298554745e05b59ad6b968f5f/2bef9/aveksha-model.png",
              "srcSet": ["/static/6f5ee7f298554745e05b59ad6b968f5f/5a46d/aveksha-model.png 300w", "/static/6f5ee7f298554745e05b59ad6b968f5f/0a47e/aveksha-model.png 600w", "/static/6f5ee7f298554745e05b59ad6b968f5f/2bef9/aveksha-model.png 1024w"],
              "sizes": "(max-width: 1024px) 100vw, 1024px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
    </div>
    <div id="stories" className={Styles.blog}>
      <h2>Stories</h2>
      <p>{props.data.allSitePage.edges.length > 0 && props.data.allSitePage.edges.map(edge => <AvekshaPostLink post={edge.node.context} mdxType="AvekshaPostLink" />)}</p>
    </div>
    <div className={Styles.book2}>
      <a href="tel:+918971544066">📱8971544066</a>
    </div>
    <footer className={Styles.footer}>
      <hr></hr>
      <div>Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a>, <a href="https://www.flaticon.com/authors/geotatah" title="geotatah">geotatah</a>, <a href="https://www.flaticon.com/authors/wanicon" title="wanicon">wanicon</a>, and <a href="https://www.flaticon.com/authors/flat-icons" title="Flat Icons">Flat Icons</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a></div>
    </footer>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      